body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Inter", sans-serif;
  background-color: #f1f5f9;
}
.body-no-scroll {
  overflow: hidden;
}
:root {
  --primary: #02a8b5;
  --secondary: hsla(0, 0%, 100%, 0.12);
  --tertiary: rgba(2, 168, 181, 0.2);
  --fontPrimary: #323a46;
  --fontSecondary: #343a40;
  --fontTertiary: #93a4ad;
  --bgcolor: #f1f5f9;
  --bgcolorDark: rgb(229, 233, 237);
  --bgHoverPrimary: #f4f9fb;
  --btnHoverPrimary: #018e98;
  --redBtnPrimary: #cd485c;
  --redBtnHover: #f1556c;
  --redBtnGS: #bf1515;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: rgba(2, 168, 181, 0.2);
}
.loading h1 {
  color: white;
}
